import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactGA from 'react-ga';
import {BsExclamationTriangle} from 'react-icons/bs';
import {FaPowerOff, FaStopCircle} from 'react-icons/fa';
import {HiSignal} from 'react-icons/hi2';
import {withRouter} from 'react-router-dom';
import SwiperCore, {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Power from '../assets/helper-icons/Power';
import AlarmMenu from '../components/AlarmMenu';
import Dispenser from '../components/Dispenser';
import Elevator from '../components/Elevator';
import Loading from '../components/Loading';
import PowerMenu from '../components/PowerMenu';
import SlotCard from '../components/SlotCard';

import {
  ALARM_ID,
  DISABLING_LEVEL,
  ROBOT_ARM_STATE,
  SLOT_TYPES,
  TARGET_STATE,
  URGENCY_LEVEL,
} from '../enums';
import {colors, mediumTextSize} from '../styles';

import packageJson from '../../package.json';
import Timer from '../components/Timer';
import {
  abortTrajectory,
  abortBehavior,
  close_ros,
  setTargetMode,
} from '../ros_init';
import {
  callService,
  convertComponentPathToReadableText,
  getInfo,
  IconComponent,
  toReadableString,
  snakeCaseToNormal,
} from '../util';
import store from '../store/index';
import {RESET_ALL} from '../actions/actionTypes';

import Basket from '../assets/helper-icons/Basket';
import CameraVideo from '../assets/helper-icons/CameraVideo';
import Diagnostics from '../assets/helper-icons/Diagnostics';
import GripHorizontal from '../assets/helper-icons/GripHorizontal';
import Jog from '../assets/helper-icons/Jog';
import OffsetTuning from '../assets/helper-icons/OffsetTuning';
import PoseCalibration from '../assets/helper-icons/PoseCalibration';
import ModeToggle from '../components/ModeToggle';
import RushModeToggle from '../components/RushModeToggle';
import TroubleshootPopup from '../components/TroubleshootPopup';
import {useTranslationAlerts} from '../hooks';
import BasketModal from '../modals/BasketModal';
import DiagnosticsModal from '../modals/DiagnosticsModal';
import ElevatorModal from '../modals/ElevatorModal';
import FryerPoseCalibrationModal from '../modals/FryerPoseCalibrationModal';
import JogConfirmation from '../modals/JogConfirmation';
import ElevatorConfirmation from '../modals/ElevatorConfirmation';
import JogModal from '../modals/JogModal';
import OffsetTuningModal from '../modals/OffsetTuningModal';
import ResolveCollisionModal from '../modals/ResolveCollisionModal';
import RobotSensorControlModal from '../modals/RobotSensorControl';

//install Swiper modules
SwiperCore.use([Pagination]);
// TODO: Refactor to use Material UI
const styles = (theme) => ({
  commandButtonHolder: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: '0.5rem',
  },
  commandButtonTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commandButton: {
    width: '5rem',
    height: '3rem',
    marginRight: '0.5rem',
    borderRadius: '1.5rem',
    border: '0.125rem solid #808080',
  },
  commandButtonAlarm: {
    border: '0.125rem solid #FC648F',
    backgroundColor: '#FCD2DE',
  },
  rightSide: {
    position: 'relative',
    color: colors.greyishBrown,
    backgroundColor: colors.whiteTwo,
    flex: 1,
    flexGrow: 6,
    overflow: 'scroll',
  },
  topRow: {
    display: 'flex',
  },
  searchArea: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonList: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing.unit * 3,
    marginTop: '2rem',
  },
  foodRowContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 3,
    marginRight: '2rem',
  },

  foodAreaContainer: {
    flexGrow: 1,
  },
  areaTitle: {
    paddingLeft: '0.75rem',
    fontWeight: 600, // TODO typography
    marginBottom: '0.1rem',
  },
  rackContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '1.25rem',
  },
  objectsContainer: {
    marginLeft: '0.75rem',
    marginRight: '0.75rem',
    marginTop: '1.5rem',
  },
  swiperSlide: {
    width: '7.75em',
  },
  toggleDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  versionWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  version: {
    position: 'sticky',
    left: '100%',
    fontSize: '1.5rem',
    background: 'rgb(104, 83, 92)',
    width: '10%',
    textAlign: 'center',
    color: 'white',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1rem',
    minHeight: '8vh',
    height: 'auto',
    background: '#68535c',
    marginRight: '0.5rem',
    minWidth: '10vw',
  },
  timeHolder: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  locationHolder: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '.5em',
    justifyContent: 'space-between',
    flex: 1,
  },
  logo: {
    width: '3em',
    height: '3em',
  },
  locationColumn: {
    paddingTop: '.5em',
    display: 'flex',
    flexDirection: 'column',
  },
  notificationContainer: {
    width: '92%',
    border: '0.2rem solid red',
    borderRadius: '0.5rem',
    padding: '0 0.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#F1CBCC',
    flexDirection: 'column',
  },
  notificationText: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: '0.2rem 0',
    listStyleType: 'disc',
    paddingLeft: '1rem',
  },
});

const ComingnextFoodTypography = withStyles({
  root: {
    paddingTop: '0.5rem',
    fontSize: '0.9375rem',
    fontWeight: 600,
    textAlign: 'center',
  },
})(Typography);

const ComingNext = withStyles(() => ({
  ComingNextSLot: {
    backgroundColor: 'white',
    borderColor: 'grey',
    borderWidth: '0.0625rem',
    borderStyle: 'solid',
    textSize: mediumTextSize,
    minHeight: '6vh',
    height: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}))((props) => {
  return (
    <div className={props.classes.ComingNextSLot}>
      <div style={{display: 'block'}}>
        <li>
          <ComingnextFoodTypography>{props.content}</ComingnextFoodTypography>
        </li>
        <li>
          <ComingnextFoodTypography style={{fontSize: '0.75rem'}}>
            {props.donenessLabel}
          </ComingnextFoodTypography>
        </li>
      </div>
    </div>
  );
});

class InstanceScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.initialState = {
      selectedError: null,
      initialCardCount: 30,
      cardWidth: 0,
      confirmModalVisible: false,
      testBehaviorRequest: {},
      testBehaviorName: '',
      troubleshootMode: false,
    };
    this.state = {...this.initialState};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.instance !== this.props.instance) {
      this.setState(this.initialState);
    }
  }

  toggleJog = async (user) => {
    const {uiState} = this.props.instanceRedux;
    const abort_request = {};
    if (!uiState) {
      window.alert('Jogging not implemented.');
      return;
    }
    const jog_request = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode:
        uiState.current_mode === TARGET_STATE.JOGGING
          ? TARGET_STATE.COOKING
          : TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason:
        uiState.current_mode === TARGET_STATE.JOGGING
          ? 'cooking'
          : 'support_jogging',
    };
    await callService(
      setTargetMode,
      this.props.instance,
      jog_request,
      'Set Manual Mode',
      null,
      async () => {
        await callService(
          abortBehavior,
          this.props.instance,
          abort_request,
          'Abort Robot Behavior',
          '',
          '',
          async () => {
            await callService(
              abortTrajectory,
              this.props.instance,
              abort_request,
              'Abort Robot Trajectory'
            );
          }
        );
      }
    );
  };

  setJogging = async (user) => {
    const {uiState} = this.props.instanceRedux;
    if (!uiState) {
      window.alert('Set Target Mode is not implemented.');
      return;
    }
    const request = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: user,
      },
      mode: TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason: 'Jogging Mode',
    };
    await callService(
      setTargetMode,
      this.props.instance,
      request,
      'Set System Access'
    );
  };

  errorSelected = (error) => {
    const {code, name} = error;
    ReactGA.event({
      category: 'Navigation',
      action: 'Open Error Modal',
      label: `[${code}] ${name}`,
    });
    this.setState({selectedError: error});
  };

  onErrorModalClose = (error) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Close Error Modal',
    });
    this.setState({selectedError: null});
  };

  containerToBasketIDSet = (container) => {
    return new Set(container.map((basket) => basket.basketID));
  };

  getMatchingBasketsWithRack = (rack) => {
    // Return all the baskets in both the rack and fryer slots.
    // let rackBasketIDs = this.containerToBasketIDSet(rack);
    const {cookingBaskets} = this.props.instanceRedux;
    let matches = [];
    cookingBaskets.forEach((basket) => {
      if (basket.basket_id !== 0) {
        // if (basket.basket_id && rackBasketIDs.has(basket.basketID)) {
        matches.push(basket.basketID);
      }
    });
    return matches;
  };

  getMatchingBasketsSet = () => {
    // Return a set of baskets in a rack and the fryer slots.
    const {inRack} = this.props.instanceRedux;
    let multiLocationBaskets = this.getMatchingBasketsWithRack(inRack);
    return new Set(multiLocationBaskets);
  };

  getSlotWidth = (itemCount) => {
    const totalMargins = 1.25 * itemCount;
    return (100 - totalMargins) / itemCount + '%';
  };

  goToHub = async () => {
    const {history, instance} = this.props;
    await close_ros(history);
    ReactGA.event({
      category: 'Navigation',
      action: 'Return to Hub Screen',
    });
    store.dispatch({
      type: RESET_ALL,
      host: instance,
    });
  };

  loadQueue = () => {
    this.setState((prevState) => {
      return {
        initialCardCount:
          prevState.initialCardCount + window.innerWidth / this.state.cardWidth,
      };
    });
  };

  handleOpenTroubleshootModal = () => {
    this.setState((prev) => ({
      ...prev,
      troubleshootMode: true,
    }));
  };
  handleCloseTroubleshootModal = () => {
    this.setState((prev) => ({
      ...prev,
      troubleshootMode: false,
    }));
  };

  handleRemoteViewerModal = (computeIPAddress) => {
    let touchscreenIP = computeIPAddress
      .split('.')
      .map((part, i, arr) => (i === arr.length - 1 ? parseInt(part) + 7 : part))
      .join('.');
    const url = 'http://' + touchscreenIP + ':6080/vnc_lite.html';
    window.open(url, '_blank');
  };

  handleCameraModal = (cameraId) => {
    const url =
      'https://dwspectrum.digital-watchdog.com/systems/1d5ddfb9-9ad9-41d0-b643-e8582dc6bf6d/view/' +
      cameraId;
    window.open(url, '_blank');
  };

  render() {
    const {troubleshootMode} = this.state;

    const {
      classes,
      instance,
      instanceRedux,
      jogConfirmation,
      elevatorConfirmation,
      troubleshootConfirmation,
      diagnosticsModal,
      jogModal,
      elevatorModal,
      resolveCollisionModal,
      offsetTuningModal,
      fryerPoseCalibrationModal,
      basketModal,
      robotSensorControlModal,
      openDiagnosticsModal,
      openJogConfirmation,
      openElevatorConfirmation,
      openTroubleshootConfirmation,
      openJogModal,
      openElevatorModal,
      openRobotSensorControl,
      closeDiagnosticsModal,
      closeJogConfirmation,
      closeElevatorConfirmation,
      closeTroubleshootConfirmation,
      closeJogModal,
      closeElevatorModal,
      closeResolveCollisionModal,
      closeOffsetTuningModal,
      closeFryerPoseCalibrationModal,
      closeBasketModal,
      closeRobotSensorControl,
      openOffsetTuningModal,
      openFryerPoseCalibrationModal,
      openBasketModal,
      user,
      computeIPAddress,
    } = this.props;
    const {
      cookingBaskets,
      shelfBaskets,
      diagnosticsState,
      gripperState,
      getGripperSensor,
      getDispenserLockSensor,
      getElevatorSensor,
      inRack,
      readyNextRack,
      notificationState,
      scannerState,
      robotArmState,
      uiState,
      homeJoints,
      hopperState,
      elevatorState,
      offsetUpdated,
      offsetDataObj,
      versionNumber,
      awsAvailable,
      rosHeaderTimeStamp,
      foodMenu,
      basketList,
      baskets,
      cameraID,
      fryersState,
      disabledFryerSensorsArray,
      numFryerSensors,
      relayState,
      rushModeFlag,
    } = instanceRedux;
    const {getMessage} = useTranslationAlerts();
    const jogging = Boolean(
      uiState && uiState.current_mode === TARGET_STATE.JOGGING
    );
    const systemAccess = Boolean(
      uiState && uiState.current_mode === TARGET_STATE.SYSTEM_ACCESS
    );
    const needHelp = Boolean(
      uiState && uiState.need_help_state === 'help_requested'
    );
    const troubleshooting = uiState && uiState.troubleshooting;
    const troubleshootRequester = uiState && uiState.troubleshoot_requester;
    const rushMode = Boolean(uiState && uiState.rush_mode);

    const modeDetails = [
      {label: 'Current Mode', value: uiState && uiState.current_mode},
      {
        label: 'Current Mode Reason',
        value: uiState && uiState.current_mode_reason,
      },
      {label: 'Target Mode', value: uiState && uiState.target_mode},
      {
        label: 'Target Mode Reason',
        value: uiState && uiState.target_mode_reason,
      },
    ];

    let holdClearMechanismStatus, cycleEStop;
    if (notificationState) {
      holdClearMechanismStatus = Object.keys(notificationState).reduce(
        function (alarm, val) {
          if (notificationState[val].id === ALARM_ID.E_STOPPED) {
            alarm[val] = notificationState[val];
          }
          return alarm;
        },
        {}
      );
      cycleEStop = Object.keys(holdClearMechanismStatus).length > 0;
    }
    let troubleshootingReady = !cycleEStop;

    let slotContent = [];
    let shelfContent = [];
    const fryerSlotWidth = this.getSlotWidth(cookingBaskets.length);
    let inRackSlotWidth = '10vw';

    const multiLocationBaskets = this.getMatchingBasketsSet();
    for (let index = 0; index < cookingBaskets.length; index++) {
      let actionSlot = cookingBaskets[index];
      // TODO(IAQ) make sub component
      slotContent.push(
        <SlotCard
          instance={instance}
          key={index}
          index={index}
          slotType={SLOT_TYPES.FRYER}
          fryersState={fryersState}
          slotObj={actionSlot}
          width={fryerSlotWidth}
          multiLocation={
            !actionSlot.empty && multiLocationBaskets.has(actionSlot.basketID)
          }
          foodMenu={foodMenu}
          rosHeaderTimeStamp={rosHeaderTimeStamp}
          user={user}
        />
      );
    }

    for (let index = 0; index < shelfBaskets.length; index++) {
      let actionSlot = shelfBaskets[index];
      shelfContent.push(
        <SlotCard
          instance={instance}
          key={index}
          index={index}
          slotType={SLOT_TYPES.SHELF}
          slotObj={actionSlot}
          width={fryerSlotWidth}
          foodMenu={foodMenu}
          rosHeaderTimeStamp={rosHeaderTimeStamp}
          user={user}
        />
      );
    }

    let inRackContent = [];
    inRack.forEach((rackSlot, index) => {
      if (rackSlot.slot_path.includes('/slot/interface/')) {
        inRackContent.push(
          <SlotCard
            instance={instance}
            key={index}
            slotType={SLOT_TYPES.INTERFACE}
            slotObj={rackSlot}
            width={inRackSlotWidth}
            multiLocation={
              rackSlot.basket_id !== 0 &&
              multiLocationBaskets.has(rackSlot.basketID)
            }
            foodMenu={instanceRedux.foodMenu}
            rosHeader={instanceRedux.rosHeader}
            user={user}
          />
        );
      }
    });

    let readyNextRackSlides = [];

    function formatFoodType(foodType) {
      const words = foodType.split('_');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      const food_type = words.join(' ');
      return food_type;
    }

    readyNextRack.forEach((rackSlot, index) => {
      let words;
      if (rackSlot.food_type !== '') {
        words = rackSlot.food_type.split('_');

        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
      }

      readyNextRackSlides.push(rackSlot);
    });
    const {name, location, logo} = getInfo(instance);
    const isFlippyOn = instanceRedux && instanceRedux.isFlippyOn;

    let notificationList = [];
    if (notificationState.length > 0) {
      notificationState.forEach((notif, key) => {
        const dispenserIssue =
          notif.code.includes('dispenser') && notif.data !== undefined;
        let {title} = getMessage(
          notif.code,
          convertComponentPathToReadableText(notif.component_path),
          dispenserIssue && JSON.parse(notif.data).slot === 'left'
            ? hopperState &&
              hopperState.sides &&
              hopperState.sides[0] &&
              hopperState.sides[0].food_type
              ? snakeCaseToNormal(hopperState.sides[0].food_type)
              : ''
            : dispenserIssue && JSON.parse(notif.data).slot === 'right'
              ? hopperState &&
                hopperState.sides &&
                hopperState.sides[1] &&
                hopperState.sides[1].food_type
                ? snakeCaseToNormal(hopperState.sides[1].food_type)
                : ''
              : dispenserIssue &&
                  JSON.parse(notif.data).slot.toLowerCase() === 'all'
                ? hopperState &&
                  hopperState.sides &&
                  hopperState.sides[0] &&
                  hopperState.sides[1]
                  ? snakeCaseToNormal(hopperState.sides[0].food_type) +
                    ' and ' +
                    snakeCaseToNormal(hopperState.sides[1].food_type)
                  : ''
                : '',
          dispenserIssue && notif.data !== undefined
            ? JSON.parse(notif.data).slot
            : '',
          notif.data
        );
        if (title !== undefined) {
          notificationList.push({
            title: title,
          });
        }
      });
    }

    scannerState &&
      scannerState.forEach((scanner, key) => {
        notificationList.push({
          title: scanner,
        });
      });

    const robotArmAlert =
      robotArmState === ROBOT_ARM_STATE.DISABLED ||
      robotArmState === ROBOT_ARM_STATE.MANUAL;

    if (robotArmAlert) {
      let robotArmAlertString = `Robot controlled in ${
        robotArmState === ROBOT_ARM_STATE.DISABLED ? ' disabled ' : ' manual '
      } mode.`;
      notificationList.push({
        title: robotArmAlertString,
      });
    }

    if (
      diagnosticsState[
        '/network_dev_monitor/network_dev_monitor: HDMI Connection: TouchScreenDiagnostics'
      ] === 2
    ) {
      notificationList.push({
        title: 'Touchscreen HDMI Disconnected',
      });
    }

    if (
      diagnosticsState[
        '/network_dev_monitor/network_dev_monitor: USB Connection: TouchScreenDiagnostics'
      ] === 2
    ) {
      notificationList.push({
        title: 'Touchscreen USB Disconnected',
      });
    }

    return (
      <div className={classes.rightSide}>
        <div className={classes.topRow}>
          <div className={classes.title}>
            <div className={classes.timeHolder}>
              <Typography variant="subtitle2" style={{color: 'white'}}>
                MSC
              </Typography>
              <Timer
                format="LTS"
                variant="subtitle1"
                style={{color: 'white'}}
              />
            </div>
            <div className={classes.locationHolder}>
              <div className={classes.locationColumn} style={{color: 'white'}}>
                <Typography style={{color: 'white'}}>{name}</Typography>
                <Typography style={{color: 'white'}}>{location}</Typography>
                <Typography style={{color: 'white'}}>
                  SW: {versionNumber}
                </Typography>
              </div>
              {logo && <img src={logo} className={classes.logo} alt="logo" />}
            </div>
          </div>
          <div className={classes.searchArea}>
            <div className={classes.toggleDiv}>
              {awsAvailable && isFlippyOn && (
                <>
                  {rushModeFlag && (
                    <RushModeToggle toggle={rushMode} instance={instance} />
                  )}
                  {needHelp && (
                    <ModeToggle
                      name="Request - In Process"
                      user={user}
                      troubleshootRequester={troubleshootRequester}
                      status={needHelp}
                      instance={instance}
                      icon={
                        <IconComponent
                          icon2="Barrier"
                          width="1.5vw"
                          color={colors.black}
                        />
                      }
                      openTroubleshootConfirmation={
                        openTroubleshootConfirmation
                      }
                    />
                  )}
                  {jogging && (
                    <ModeToggle
                      name="Jogging"
                      status={jogging}
                      instance={instance}
                      icon={
                        <FaStopCircle
                          style={{width: '1.5vw', height: '1.5vw'}}
                        />
                      }
                      openJogConfirmation={openJogConfirmation}
                    />
                  )}
                  {/* {troubleshootMode && (
                    <TroubleshootPopup
                      instance={instance}
                      open={troubleshootMode}
                      onClose={this.handleCloseTroubleshootModal}
                      troubleshooting={troubleshooting}
                      user={user}
                    />
                  )} */}
                </>
              )}
            </div>
            <div className={classes.buttonList}>
              <div className={classes.commandButtonHolder}>
                <div className={classes.commandButtonTop}>
                  <IconButton
                    className={classes.commandButton}
                    aria-label="Hub"
                    onClick={this.goToHub}
                    data-tip="Return to HUB Screen"
                  >
                    <GripHorizontal />
                  </IconButton>
                  {awsAvailable && (
                    <>
                      {isFlippyOn && (
                        <IconButton
                          className={classes.commandButton}
                          aria-label="RobotSensorControl"
                          onClick={openRobotSensorControl}
                          data-tip={'Robot Sensor Control'}
                          color={'inherit'}
                        >
                          <HiSignal color="black" />
                        </IconButton>
                      )}
                    </>
                  )}
                  <IconButton
                    className={classes.commandButton}
                    aria-label="RemoteViewer"
                    onClick={() =>
                      this.handleRemoteViewerModal(computeIPAddress)
                    }
                    data-tip="Remote Viewer"
                  >
                    <IconComponent
                      icon2="RemoteViewer"
                      width="2.1875rem"
                      height="1.875rem"
                      color="#525252"
                    />
                  </IconButton>
                  {awsAvailable && (
                    <>
                      {isFlippyOn && (
                        <>
                          <IconButton
                            className={classes.commandButton}
                            aria-label="CameraIntegration"
                            onClick={() =>
                              this.handleCameraModal(cameraID.left)
                            }
                            data-tip="Camera Integration"
                          >
                            <CameraVideo />
                          </IconButton>
                          <IconButton
                            className={classes.commandButton}
                            aria-label="basket"
                            onClick={openBasketModal}
                            data-tip="Basket Modal"
                          >
                            <Basket />
                          </IconButton>
                          <AlarmMenu
                            alarms={notificationState}
                            classes={{
                              button:
                                classes.commandButton +
                                (notificationState.length > 0
                                  ? ' ' + classes.commandButtonAlarm
                                  : ''),
                            }}
                          />
                          {troubleshooting !== undefined &&
                            troubleshooting !== null && (
                              <IconButton
                                className={classes.commandButton}
                                aria-label="Barrier"
                                onClick={this.handleOpenTroubleshootModal}
                              >
                                <IconComponent
                                  icon2="Barrier"
                                  width="2.4375rem"
                                  height="2.375rem"
                                  color={
                                    !troubleshooting
                                      ? '#525252'
                                      : troubleshootingReady
                                        ? colors.green
                                        : colors.darkRed
                                  }
                                />
                              </IconButton>
                            )}
                          <IconButton
                            className={
                              classes.commandButton +
                              (jogging ? ' ' + classes.commandButtonAlarm : '')
                            }
                            aria-label="Jog"
                            onClick={openJogConfirmation}
                            data-tip={jogging ? 'Resume Actions' : 'Jog Robot'}
                            color={jogging ? 'secondary' : 'inherit'}
                          >
                            <Jog jogging={jogging} />
                          </IconButton>
                          <IconButton
                            className={classes.commandButton}
                            aria-label="Diagnostics"
                            onClick={openDiagnosticsModal}
                            data-tip="Diagnostics Modal"
                          >
                            <Diagnostics />
                          </IconButton>
                          <IconButton
                            className={classes.commandButton}
                            aria-label="fryerPoseCalibration"
                            onClick={openFryerPoseCalibrationModal}
                            data-tip="Fryer Pose Calibration Modal"
                          >
                            <PoseCalibration />
                          </IconButton>
                          <IconButton
                            className={classes.commandButton}
                            aria-label="offsetTuning"
                            onClick={openOffsetTuningModal}
                            data-tip="Offset Tuning Modal"
                          >
                            <OffsetTuning />
                          </IconButton>
                        </>
                      )}
                      <PowerMenu
                        instanceStates={instanceRedux}
                        instance={instance}
                        component={
                          <Power
                            color={colors.greyishBrown}
                            width="2.5rem"
                            height="2.5rem"
                          />
                        }
                        relayState={relayState}
                      />
                    </>
                  )}
                </div>
                {isFlippyOn && (
                  <div className={classes.notificationContainer}>
                    {modeDetails.map((detail, index) => (
                      <Typography key={index}>
                        <b>{detail.label}:</b> {' ' + detail.value}
                      </Typography>
                    ))}
                  </div>
                )}
                {isFlippyOn && notificationList.length > 0 && (
                  <div className={classes.notificationContainer}>
                    <ul className={classes.notificationText}>
                      {notificationList.map((list, key) => (
                        <li key={key}>{`${list.title}`}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {awsAvailable && isFlippyOn ? (
          <div className={classes.objectsContainer}>
            <div className={classes.rackContainer}>
              <div style={{display: 'contents'}}>
                <div
                  className={classes.foodAreaContainer}
                  // style={{width: inRackWidth}}
                >
                  <div className={classes.areaTitle}>Autobasket</div>
                  <div className={classes.foodRowContainer}>
                    {inRackContent}
                  </div>
                </div>
              </div>
              <div
                className={classes.foodAreaContainer}
                style={{width: '46.5vw'}}
              >
                <div className={classes.areaTitle}>Dispenser</div>
                {/* This will be our new Dispenser */}
                <div>
                  <Dispenser hopperState={hopperState} />
                </div>
              </div>
              <div
                className={classes.foodAreaContainer}
                style={{
                  width: '30vw',
                }}
              >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className={classes.areaTitle}>Elevator</div>
                  {elevatorState &&
                    elevatorState.notifications &&
                    elevatorState.notifications.length > 0 && (
                      <div style={{marginRight: '1rem'}}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            className={classes.areaTitle}
                            style={{color: '#FC648F', fontWeight: 'bold'}}
                          >
                            {toReadableString(
                              elevatorState.notifications[0].code
                            ) + ' Error'}
                          </Typography>
                          <BsExclamationTriangle
                            style={{
                              marginLeft: '1rem',
                              color: '#FC648F',
                              width: '1.5rem',
                              height: '1.5rem',
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {/* Elevator component */}
                <div>
                  <Elevator
                    elevatorState={elevatorState}
                    openElevatorModal={openElevatorModal}
                    jogging={jogging}
                    openElevatorConfirmation={openElevatorConfirmation}
                  />
                </div>
              </div>
            </div>
            <div style={{width: '100%'}}>
              <div className={classes.areaTitle}>Fryer Slot</div>
              <div
                className={classes.foodRowContainer}
                style={{minHeight: '25vh'}}
              >
                {slotContent}
              </div>
            </div>
            <div style={{width: '100%'}}>
              <div className={classes.areaTitle}>Shelf Slot</div>
              <div
                className={classes.foodRowContainer}
                style={{minHeight: '20vh'}}
              >
                {shelfContent}
              </div>
            </div>
            <div style={{display: 'flex'}}>
              <div style={{width: '100%'}}>
                <div className={classes.areaTitle}>Coming Next</div>
                <div>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    grabCursor={true}
                    pagination={{
                      el: '.swiper-pagination',
                      clickable: true,
                    }}
                    className="mySwiper"
                    onReachEnd={() => this.loadQueue()}
                  >
                    {readyNextRackSlides.map(
                      (data, index) =>
                        index < this.state.initialCardCount && (
                          <SwiperSlide
                            key={index}
                            className={classes.swiperSlide}
                          >
                            <div
                              ref={(node) =>
                                node &&
                                this.setState({
                                  cardWidth: node.offsetWidth,
                                })
                              }
                            >
                              {data.food_type !== '' && (
                                <ComingNext
                                  content={formatFoodType(data.food_type)}
                                  donenessLabel={data.doneness}
                                />
                              )}
                            </div>
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        ) : !isFlippyOn ? (
          <div
            style={{
              width: '99%',
              height: '85%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{textAlign: 'center', fontSize: '1.5rem'}}>
              Flippy's Software is Off.
              <br />
              Start Up Software through Power Menu <FaPowerOff color="black" />
            </p>
          </div>
        ) : (
          <Loading status={!awsAvailable} />
        )}
        {awsAvailable && (
          <>
            <JogConfirmation
              open={jogConfirmation}
              onClose={closeJogConfirmation}
              instance={instance}
              jogging={jogging}
              openJogModal={openJogModal}
              jogRobot={() => this.toggleJog(user)}
              alarms={notificationState}
            />
            <ElevatorConfirmation
              open={elevatorConfirmation}
              onClose={closeElevatorConfirmation}
              jogging={jogging}
              jogRobot={() => this.toggleJog(user)}
              openElevatorModal={openElevatorModal}
            />
            <JogModal
              open={jogModal}
              jogging={jogging}
              onClose={closeJogModal}
              instance={instance}
              gripperState={gripperState}
              getGripperSensor={getGripperSensor}
              alarms={notificationState}
              homeJoints={homeJoints}
              notificationState={notificationState}
              troubleshooting={troubleshooting}
              troubleshootRequester={troubleshootRequester}
              elevatorState={elevatorState}
            />
            <ElevatorModal
              open={elevatorModal}
              jogging={jogging}
              onClose={closeElevatorModal}
              instance={instance}
              elevatorState={elevatorState}
            />
            <ResolveCollisionModal
              open={resolveCollisionModal}
              openJogModal={openJogModal}
              jogging={jogging}
              jogRobot={() => {
                this.toggleJog(user);
                closeResolveCollisionModal();
              }}
              onClose={closeResolveCollisionModal}
              notificationState={notificationState}
              elevatorState={elevatorState}
              troubleshooting={troubleshooting}
              troubleshootRequester={troubleshootRequester}
              troubleshootingReady={troubleshootingReady}
            />
            <DiagnosticsModal
              open={diagnosticsModal}
              onClose={closeDiagnosticsModal}
              diagnosticsState={diagnosticsState}
            />
            <RobotSensorControlModal
              open={robotSensorControlModal}
              onClose={closeRobotSensorControl}
              disabledFryerSensorsArray={disabledFryerSensorsArray}
              numFryerSensors={numFryerSensors}
              getDispenserLockSensor={getDispenserLockSensor}
              getGripperSensor={getGripperSensor}
              getElevatorSensor={getElevatorSensor}
              instance={instance}
              relayState={relayState}
            />
            <OffsetTuningModal
              open={offsetTuningModal}
              onClose={closeOffsetTuningModal}
              fryerNumber={fryersState.length}
              instance={instance}
              offsetUpdated={offsetUpdated}
              offsetDataObj={offsetDataObj}
            />
            <FryerPoseCalibrationModal
              open={fryerPoseCalibrationModal}
              onClose={closeFryerPoseCalibrationModal}
              user={user}
              instance={instance}
              uiState={uiState}
            />
            <BasketModal
              open={basketModal}
              onClose={closeBasketModal}
              instance={instance}
              basketList={basketList}
              baskets={baskets}
              user={user}
              rosHeaderTimeStamp={rosHeaderTimeStamp}
            />
            <TroubleshootPopup
              instance={instance}
              open={troubleshootConfirmation}
              onClose={closeTroubleshootConfirmation}
              troubleshooting={needHelp}
              user={user}
            />
            <Typography variant="body2" className={classes.version}>
              {packageJson.version}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

InstanceScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string.isRequired,
  instanceRedux: PropTypes.object.isRequired,
  jogConfirmation: PropTypes.bool.isRequired,
  diagnosticsModal: PropTypes.bool.isRequired,
  jogModal: PropTypes.bool.isRequired,
  resolveCollisionModal: PropTypes.bool.isRequired,
  openDiagnosticsModal: PropTypes.func.isRequired,
  openJogConfirmation: PropTypes.func.isRequired,
  openJogModal: PropTypes.func.isRequired,
  openResolveCollisionModal: PropTypes.func.isRequired,
  closeDiagnosticsModal: PropTypes.func.isRequired,
  closeJogConfirmation: PropTypes.func.isRequired,
  closeJogModal: PropTypes.func.isRequired,
  closeResolveCollisionModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(InstanceScreen));
