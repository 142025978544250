import {combineReducers} from 'redux';

import appState from './appState';
import {ui} from './uiReducer';

import {
  RESET_ALL,
  SET_ALARM_STATE,
  SET_BASKET_STATES,
  SET_COOKING_STATES,
  SET_SHELF_STATES,
  SET_FLIPPY_ON,
  SET_MQTT_CONNECTION,
  SET_DIAGNOSTICS_STATE,
  SET_FOODS,
  SET_CAMERA_ID,
  SET_BASKET_LIST,
  SET_GET_GRIPPER_SENSOR,
  SET_GET_DISPENSER_LOCK_SENSOR,
  SET_GET_ELEVATOR_SENSOR,
  SET_GRIPPER_STATE,
  SET_HOME_JOINTS,
  SET_HOPPER_STATE,
  SET_ELEVATOR_STATE,
  SET_FRYERS_STATE,
  SET_RELAY_STATE,
  SET_NOTIFICATION_STATE,
  SET_IN_RACK_STATES,
  SET_MISO_MSG,
  SET_OFFSET_DATA,
  SET_OFFSET_UPDATED,
  SET_READY_NEXT_RACK_STATES,
  SET_AWS_AVAILABLE,
  SET_ROBOT_ARM_STATE,
  SET_ROS_HEADER_TIME_STAMP,
  SET_SCANNER_STATE,
  SET_UI_STATE,
  SET_VERSION_NUMBER,
  SET_DISABLED_FRYER_SENSOR_ARRAY,
  SET_NUM_FRYER_SENSORS,
  SET_RUSH_MODE_FLAG,
} from '../actions/actionTypes';

const initialInstanceState = {
  // NOTE(NK): Don't reset ROS params
  alarmState: [],
  fryersState: [],
  robotArmState: null,
  scannerState: [],
  cookDonenessList: {},
  preDonenessList: [],
  isFlippyOn: false,
  isMQTTConnected: false,
  inRack: [],
  readyNextRack: [],
  baskets: [],
  cookingBaskets: [],
  shelfBaskets: [],
  uiState: null,
  gripperState: null,
  homeJoints: [],
  foodMenu: [
    // FIXME(JRA): None of this should be here. The ROS Param get request does not resolve in many cases,
    // and hangs indefinitely. This means that in most ChefUI instances, we're using these defaults.
    // If we want to ever be able to change food cook times from the ChefUI, all this code will need to be replaced.
  ],
  cameraID: {},
  basketList: {},
  hopperState: {},
  elevatorState: {},
  notificationState: [],
  diagnosticsState: {},
  misoMsgState: {
    open: false,
    message: '',
  },
  versionNumber: '',
  offsetUpdated: {},
  rosHeaderTimeStamp: {},
  offsetDataObj: {},
  awsAvailable: false,
  disabledFryerSensorsArray: [],
  numFryerSensors: {},
  getDispenserLockSensor: false,
  getElevatorSensor: false,
  relayState: [],
  rushModeFlag: false,
};

const instances = (state = initialInstanceState, action) => {
  let extra = {};
  let attr = null;

  if (!action.host) {
    console.log('No host');
    return {...state};
  }

  const prevInstance = state[action.host];

  switch (action.type) {
    case SET_ALARM_STATE:
      attr = 'alarmState';
      break;
    case SET_ROBOT_ARM_STATE:
      attr = 'robotArmState';
      break;
    case SET_DIAGNOSTICS_STATE:
      attr = 'diagnosticsState';
      break;
    case SET_FOODS:
      attr = 'foodMenu';
      break;
    case SET_CAMERA_ID:
      attr = 'cameraID';
      break;
    case SET_BASKET_LIST:
      attr = 'basketList';
      break;
    case SET_HOME_JOINTS:
      attr = 'homeJoints';
      break;
    case SET_SCANNER_STATE:
      attr = 'scannerState';
      break;
    case SET_IN_RACK_STATES:
      attr = 'inRack';
      break;
    case SET_READY_NEXT_RACK_STATES:
      attr = 'readyNextRack';
      break;
    case SET_BASKET_STATES:
      attr = 'baskets';
      break;
    case SET_COOKING_STATES:
      attr = 'cookingBaskets';
      break;
    case SET_SHELF_STATES:
      attr = 'shelfBaskets';
      break;
    case SET_GRIPPER_STATE:
      attr = 'gripperState';
      break;
    case SET_UI_STATE:
      attr = 'uiState';
      break;
    case SET_HOPPER_STATE:
      attr = 'hopperState';
      break;
    case SET_ELEVATOR_STATE:
      attr = 'elevatorState';
      break;
    case SET_FRYERS_STATE:
      attr = 'fryersState';
      break;
    case SET_RELAY_STATE:
      attr = 'relayState';
      break;
    case SET_NOTIFICATION_STATE:
      attr = 'notificationState';
      break;
    case SET_FLIPPY_ON:
      attr = 'isFlippyOn';
      break;
    case SET_MQTT_CONNECTION:
      attr = 'isMQTTConnected';
      break;
    case SET_MISO_MSG:
      attr = 'misoMsgState';
      break;
    case SET_VERSION_NUMBER:
      attr = 'versionNumber';
      break;
    case SET_GET_GRIPPER_SENSOR:
      attr = 'getGripperSensor';
      break;
    case SET_GET_DISPENSER_LOCK_SENSOR:
      attr = 'getDispenserLockSensor';
      break;
    case SET_GET_ELEVATOR_SENSOR:
      attr = 'getElevatorSensor';
      break;
    case SET_OFFSET_UPDATED:
      attr = 'offsetUpdated';
      break;
    case SET_ROS_HEADER_TIME_STAMP:
      attr = 'rosHeaderTimeStamp';
      break;
    case SET_OFFSET_DATA:
      attr = 'offsetDataObj';
      break;
    case SET_AWS_AVAILABLE:
      attr = 'awsAvailable';
      break;
    case SET_DISABLED_FRYER_SENSOR_ARRAY:
      attr = 'disabledFryerSensorsArray';
      break;
    case SET_NUM_FRYER_SENSORS:
      attr = 'numFryerSensors';
      break;
    case SET_RUSH_MODE_FLAG:
      attr = 'rushModeFlag';
      break;
    case RESET_ALL:
      const {[action.host]: _, ...newState} = state;

      return newState;
    default:
      return {...state};
  }
  // Do nothing if nothing changed to not have to retrigger state update
  if (prevInstance && attr) {
    // TODO(NK): Do a deep comparison
    if (prevInstance[attr] === action.payload) {
      return {...state};
    }
  }
  extra[attr] = action.payload;

  let instance = {};
  instance[action.host] = {
    ...initialInstanceState,
    ...prevInstance,
    ...extra,
  };

  return {
    ...state,
    ...instance,
  };
};

const rootReducer = combineReducers({
  instances,
  ui,
  appState,
  dispatch: (state = {}) => state,
  subscribe: (state = {}) => state,
  getState: (state = {}) => state,
  replaceReducer: (state = {}) => state,
  liftedStore: (state = {}) => state,
});

export default rootReducer;
